<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top" v-if="eventConfig">
    <div class="container-fluid">
      <a class="navbar-brand" href="/" v-if="eventConfig">
        <img title="Logo" :alt="eventConfig.titleLang1" v-if="!darkMode" :src="eventConfig.menulogo && eventConfig.menulogo.length > 0
          ? eventConfig.menulogo[0].url
          : '/dist/img/mute-logo-light.png'
          " height="30" />
        <img title="Logo" :alt="eventConfig.titleLang1" v-if="darkMode" :src="eventConfig.menulogoDark && eventConfig.menulogoDark.length > 0
          ? eventConfig.menulogoDark[0].url
          : (eventConfig.menulogo && eventConfig.menulogo.length > 0
            ? eventConfig.menulogo[0].url
            : '/dist/img/mute-logo-dark.png')
          " height="30" />
      </a>
      <ul class="navbar-nav inline-flex d-lg-none ml-auto">
        <li class="navbar-item" v-if="isAuthenticated && eventConfig.chatEnabled">
          <router-link class="nav-link" to="/chat">
            <span><i title="Chat" class="pi pi-comments ms-2 me-2" v-if="unreadChatRooms != '0'" style="font-size: 1.4rem"
                v-badge="unreadChatRooms"></i></span>
            <span><i title="Chat" class="pi pi-comments ms-2 me-2" v-if="unreadChatRooms == '0'"
                style="font-size: 1.4rem"></i></span>
          </router-link>
        </li>
      </ul>
      <button id="collapser" class="navbar-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" @click="magicHide">
        <ul class="navbar-nav">
          <template v-for="menu in menuItems">
            <b-nav-item-dropdown :text="menu['title_lang' + langVal]" v-if="menu.sub_menu" :key="menu.menu_id">
              <b-dropdown-item v-for="smenu in menu.sub_menu" :to="'/' + smenu.link" :key="smenu.menu_id">
                {{ smenu["title_lang" + langVal] }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item v-else :to="'/' + menu.link" :key="menu.menu_id + 'e'">
              {{ menu["title_lang" + langVal] }}
            </b-nav-item>
          </template>
          <b-nav-item to="/livestream" v-if="isAuthenticated && eventConfig.livestreamEnabled">
            {{ $t("backend.livestream") }}
          </b-nav-item>
          <li class="nav-item" v-if="isAuthenticated && eventConfig.agendaEnabled">
            <router-link class="nav-link" to="/calendar">{{ $t("backend.timetable") }}</router-link>
          </li>
          <b-nav-item to="/stage/1" v-if="isAuthenticated && eventConfig.stage1Enabled">
            {{
              this.$i18n.locale == eventConfig.lang3
              ? eventConfig.stage1TitleLang3
              : this.$i18n.locale == eventConfig.lang2
                ? eventConfig.stage1TitleLang2
                : eventConfig.stage1TitleLang1
            }}
          </b-nav-item>
          <b-nav-item to="/stage/2" v-if="isAuthenticated && eventConfig.stage2Enabled">
            {{
              this.$i18n.locale == eventConfig.lang3
              ? eventConfig.stage2TitleLang3
              : this.$i18n.locale == eventConfig.lang2
                ? eventConfig.stage2TitleLang2
                : eventConfig.stage2TitleLang1
            }}
          </b-nav-item>
          <b-nav-item to="/stage/3" v-if="isAuthenticated && eventConfig.stage3Enabled">
            {{
              this.$i18n.locale == eventConfig.lang3
              ? eventConfig.stage3TitleLang3
              : this.$i18n.locale == eventConfig.lang2
                ? eventConfig.stage3TitleLang2
                : eventConfig.stage3TitleLang1
            }}
          </b-nav-item>
          <b-nav-item to="/stage/4" v-if="isAuthenticated && eventConfig.stage4Enabled">
            {{
              this.$i18n.locale == eventConfig.lang3
              ? eventConfig.stage4TitleLang3
              : this.$i18n.locale == eventConfig.lang2
                ? eventConfig.stage4TitleLang2
                : eventConfig.stage4TitleLang1
            }}
          </b-nav-item>
          <b-nav-item to="/stage/5" v-if="isAuthenticated && eventConfig.stage5Enabled">
            {{
              this.$i18n.locale == eventConfig.lang3
              ? eventConfig.stage5TitleLang3
              : this.$i18n.locale == eventConfig.lang2
                ? eventConfig.stage5TitleLang2
                : eventConfig.stage5TitleLang1
            }}
          </b-nav-item>
          <b-nav-item to="/stage/6" v-if="isAuthenticated && eventConfig.stage6Enabled">
            {{
              this.$i18n.locale == eventConfig.lang3
              ? eventConfig.stage6TitleLang3
              : this.$i18n.locale == eventConfig.lang2
                ? eventConfig.stage6TitleLang2
                : eventConfig.stage6TitleLang1
            }}
          </b-nav-item>
          <b-nav-item to="/stage/7" v-if="isAuthenticated && eventConfig.stage7Enabled">
            {{
              this.$i18n.locale == eventConfig.lang3
              ? eventConfig.stage7TitleLang3
              : this.$i18n.locale == eventConfig.lang2
                ? eventConfig.stage7TitleLang2
                : eventConfig.stage7TitleLang1
            }}
          </b-nav-item>
          <template v-if="isAuthenticated">
            <template v-for="(value, num) in eventConfig.webinarsMenu">
              <b-nav-item :to="'/webinar/watch/' + num + ''" :key="num">
                {{ value[langVal] }}
              </b-nav-item>
            </template>
          </template>
          <b-nav-item :to="{ name: 'participants' }" v-if="isAuthenticated && eventConfig.participantsEnabled">
            {{ $t("Profile.participants") }}
          </b-nav-item>
          <b-nav-item v-if="isAuthenticated && eventConfig.roomsEnabled" :to="{ name: 'Rooms' }">
            {{ $t("backend.rooms") }}
          </b-nav-item>
          <b-nav-item v-if="isAuthenticated && eventConfig.webinarsEnabled" :to="{ name: 'webinar-list' }">
            {{ $t("backend.webinars") }}
          </b-nav-item>
          <b-nav-item :to="{ name: 'admin-dashboard' }" v-if="currentUser.is_admin == 1">
            <i class="ri-settings-fill"></i>
            {{ $t("backend.admin") }}
          </b-nav-item>
        </ul>
        <b-navbar-nav class="ms-auto">
          <b-nav-item to="/chat" class="" v-if="isAuthenticated && eventConfig.chatEnabled" title="Chat">
            <span><i class="pi pi-comments" v-if="unreadChatRooms != '0'" v-badge="unreadChatRooms"></i></span>
            <span><i class="pi pi-comments" v-if="unreadChatRooms == '0'"></i></span>
            <span class="d-inline d-lg-none">Chat</span>
            <!--{{ $t("backend.chat") }} ({{ unreadChatRooms }})-->
          </b-nav-item>
          <b-nav-item :to="{ name: 'Chat' }" v-if="isAuthenticated &&
            rcLoginInformaton != '' &&
            eventConfig.rcEnabled
            ">
            <i class="ri-chat-3-fill"></i>
            {{ $t("backend.chat") }}
            <transition name="slide-fade"><span v-if="rc_unreadCount" class="badge bg-warning text-dark">
                {{ rc_unreadCount }}</span></transition>
          </b-nav-item>
          <div v-if="isAuthenticated && eventConfig.chatEnabled" class="vr ms-1 me-1 d-none d-lg-block"></div>
          <b-nav-item-dropdown :title="$t('language')" v-if="eventConfig.lang2 != ''">
            <template slot="button-content">
              <i class="pi pi-language" />
              <span class="d-inline d-lg-none">{{ $t('language') }}</span>
            </template>
            <b-dropdown-item :active="$i18n.locale == eventConfig.lang1" class="" v-if="eventConfig.lang2 != ''"
              @click="changeLanguage(eventConfig.lang1)">{{
                getLanguageName(eventConfig.lang1) }}</b-dropdown-item>
            <b-dropdown-item :active="$i18n.locale == eventConfig.lang2" class="" v-if="eventConfig.lang2 != ''"
              @click="changeLanguage(eventConfig.lang2)">{{
                getLanguageName(eventConfig.lang2) }}</b-dropdown-item>
            <b-dropdown-item :active="$i18n.locale == eventConfig.lang3" class="" v-if="eventConfig.lang3 != ''"
              @click="changeLanguage(eventConfig.lang3)">{{
                getLanguageName(eventConfig.lang3) }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="eventConfig.forceColorMode == 0" :title="$t('colormode')">
            <template slot="button-content">
              <i class="pi pi-sun" v-if="colorMode == 'light'" />
              <i class="pi pi-moon" v-if="colorMode == 'dark'" />
              <!-- <i class="ri-contrast-fill" v-if="colorMode == 'auto'" style="font-size: 1em;line-height: 1em;" /> -->
              <i class="ri-contrast-fill" v-if="colorMode == 'auto'" />
              <span class="d-inline d-lg-none">{{ $t('colormode') }}</span>
            </template>
            <b-dropdown-item :active="colorMode == 'light'" @click="changeColorMode('light')"><i class="pi pi-sun" /> {{
              $t('colormode.light') }}</b-dropdown-item>
            <b-dropdown-item :active="colorMode == 'dark'" @click="changeColorMode('dark')"><i class="pi pi-moon" /> {{
              $t('colormode.dark') }}</b-dropdown-item>
            <b-dropdown-item :active="colorMode == 'auto'" @click="changeColorMode('auto')"><i class="ri-contrast-fill" />
              {{
                $t('colormode.auto') }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- <div class="vr ms-1 me-1 d-none d-lg-block"></div> -->
          <b-nav-item to="/login" v-if="!isAuthenticated && eventConfig.loginEnabled == 1">
            {{ $t("backend.login") }}
          </b-nav-item>
          <b-nav-item to="/signup" v-if="!isAuthenticated && eventConfig.signupEnabled == 1">
            {{ $t("backend.signup") }}
          </b-nav-item>
          <div v-if="offline">OFFLINE</div>
          <b-nav-item-dropdown v-if="isAuthenticated" :title="currentUser.name">
            <template slot="button-content">
              <img v-if="currentUser.image" class="rounded-circle float-start" style="
                                                                  width: 2rem;
                                                                  height: 2rem;
                                                                  position: relative;
                                                                  margin-right: 0.25rem;
                                                                  margin-top: -0.25rem;
                                                                " :src="currentUser.image" />
              <div v-if="!currentUser.image" class="rounded-circle float-start text-muted bg-body-secondary" style="
                                                                  width: 2rem;
                                                                  height: 2rem;
                                                                  position: relative;
                                                                  margin-right: 0.25rem;
                                                                  margin-top: -0.25rem;
                                                                ">
                <i class="ri-user-line noprofilepic"></i>
              </div>
              <span class="d-inline d-lg-none">{{ currentUser.name }}</span>
            </template>
            <b-dropdown-text style="min-width: 300px">
              <RwvParticipantsPreview @navigation="alert('hallo')" :participant="{
                name: currentUser.name, user_id: currentUser.user_id, title: currentUser.title,
                image: currentUser.image ? currentUser.image : null
              }" :selectable="false" :createChat="false"
                :navigationEnabled="false" />
            </b-dropdown-text>
            <b-dropdown-item :to="'/participant/' + currentUser.user_id">
              {{ $t("user.profile.show") }}</b-dropdown-item>
            <b-dropdown-item v-if="eventConfig.participantsEnabled" to="/profileEdit">
              {{ $t("user.profile.edit") }}</b-dropdown-item>
            <b-dropdown-item to="/profilePicture">
              {{ $t("user.profilepicture.edit") }}</b-dropdown-item>
              <b-dropdown-divider />
            <b-dropdown-item :link-classes="[/*'btn btn-info rounded-0', 'me-lg-2', 'text-dark'*/]"
              v-if="eventConfig.preregistrationMenu" :to="{ name: 'Preregistration' }">
              {{ $t("user.preregistration") }}
            </b-dropdown-item>
            <b-dropdown-item :link-classes="[/* 'btn btn-info rounded-0', /*'me-lg-2', 'text-dark'*/]"
              v-if="eventConfig.checkinEnabled" :to="{ name: 'Checkin' }" :title='$t("backend.checkin")'>
              <span class="">{{ $t("backend.checkin") }}</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="eventConfig.userinfoFormMenu" :to="{ name: 'Userinfo' }">
              {{ $t("backend.userinfo") }}
            </b-dropdown-item>
            <b-dropdown-divider v-if="eventConfig.preregistrationMenu || eventConfig.checkinEnabled || eventConfig.userinfoFormMenu"/>
            <b-dropdown-item to="/account">{{
              $t("user.basedata.edit")
            }}</b-dropdown-item>
            <b-dropdown-item @click="logout">{{
              $t("user.logout")
            }}</b-dropdown-item>

            <b-dropdown-divider />
            <b-dropdown-text class="m-3"><small><a target="_blank"
                  href="https://mu-te.de/impressum/">Impressum&nbsp;&amp;&nbsp;Datenschutz</a>
                <br />
                <a target="_blank" href="https://vi-meet.de/agb.html">AGB</a> |
                <a target="_blank" href="https://wiki.mute-events.de/">Hilfe</a>
              </small>
            </b-dropdown-text>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import {
  LOGOUT,
  RC_LOGOUT,
  FETCH_MENU,
  FETCH_MENU_PUBLIC,
  CLEAR_MENU,
  UPDATE_USERLANG,
} from "@/store/actions.type";
import { SET_LIVECHATENABLED } from "../store/mutations.type";
import { BACKEND_URL } from "@/common/config";
import { USER_IMAGE_URL } from "@/common/config";
import RwvParticipantsPreview from "./VParticipantsPreview";

export default {
  name: "TheHeader",
  components: {
    RwvParticipantsPreview,
  },
  computed: {
    ...mapGetters([
      "unreadChatRooms",
      "currentUser",
      "isAuthenticated",
      "rc_unreadCount",
      "rcLoginInformaton",
      "eventConfig",
      "liveChatEnabled",
      "menuItems",
      "isMenuLoading",
      "darkMode",
      "offline",
    ]),
    langVal: function () {
      if (this.$i18n.locale == this.eventConfig.lang1) return 1;
      if (this.$i18n.locale == this.eventConfig.lang2) return 2;
      if (this.$i18n.locale == this.eventConfig.lang3) return 3;
      return 1;
    },
  },
  mounted() {
    const storedTheme = localStorage.getItem('theme');

    if (storedTheme) {
      this.changeColorMode(storedTheme);
    } else {
      this.changeColorMode('auto');
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      this.changeColorMode(this.colorMode);
    })
  },
  data() {
    return {
      backend_url: BACKEND_URL,
      userImageUrl: USER_IMAGE_URL,
      colorMode: 'auto',
      eventConfigLoaded: false,
    };
  },

  watch: {
    isAuthenticated(newValue) {
      if (newValue == true) {
        this.$store.dispatch(FETCH_MENU);
      } else {
        this.$store.dispatch(CLEAR_MENU);
        this.$store.dispatch(FETCH_MENU_PUBLIC);
      }
    },
    'currentUser.lang'(newValue) {
      if (newValue)
        this.$i18n.locale = newValue;
    },
    eventConfig(newValue) {
      this.eventConfigLoaded = true;
      if (this.isAuthenticated) {
        this.$store.dispatch(FETCH_MENU);
      } else {
        this.$store.dispatch(FETCH_MENU_PUBLIC);
      }
      this.changeColorMode(this.colorMode);
      if (newValue["livechatEnabled"] != this.liveChatEnabled) {
        this.$store.commit(SET_LIVECHATENABLED, true);
        (function (w, d, s, u) {
          w.RocketChat = function (c) {
            w.RocketChat._.push(c);
          };
          w.RocketChat._ = [];
          w.RocketChat.url = u;
          var h = d.getElementsByTagName(s)[0],
            j = d.createElement(s);
          j.async = true;
          j.src = newValue["livechatScript"];
          h.parentNode.insertBefore(j, h);
          w.RocketChat(function () {
            this.setLanguage("de");
          });
        })(window, document, "script", newValue["livechatUrl"]);
      }
    },
  },

  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
      this.$notification.success(this.$t("user.auth.logoff.success"));
    },
    magicHide() {
      const menuToggle = document.getElementById('navbarSupportedContent')
      menuToggle.classList.remove('show');
      //document.getElementById("collapser").click();
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      if (this.isAuthenticated) {
        this.$store.dispatch(UPDATE_USERLANG, lang)
      }
    },
    getLanguageName(lang) {
      if (lang.startsWith('de')) {
        return "Deutsch";
      }
      if (lang.startsWith('en')) {
        return "English";
      }
      return lang;
    },
    changeColorMode(mode) {
      let newColor = mode;
      this.colorMode = mode;
      localStorage.setItem('theme', mode);

      if (mode == 'auto') {
        newColor = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
      }

      if (this.eventConfig?.forceColorMode == 1) {
        newColor = 'light'
      } else if (this.eventConfig?.forceColorMode == 2) {
        newColor = 'dark'
      }

      if (newColor == 'dark') {
        this.$store.dispatch('updateDarkMode', true);
      } else {
        this.$store.dispatch('updateDarkMode', false);
      }

      document.documentElement.setAttribute('data-bs-theme', newColor);
    },
  },
};
</script>

<style lang="css">
.navbar-nav .nav-link:hover {
  /*color: #16181b;*/
  text-decoration: none;
  background-color: var(--bs-secondary-bg);
}

.nav-link {
  white-space: nowrap;
}

/*.navbar-nav .show>.nav-link {}*/
</style>

<style lang="css" scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

/*.show
{
  height: 100vh;
}*/

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.noprofilepic {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
